<template>
  <div>
    <v-alert
        style="background-color: #5a83d9 !important;"
        type="info"
        transition="scroll-y-transition"
        v-if="stencilInfo && componentType==='variable'"
    >
      {{ language.stencil.variableInfo }}
    </v-alert>
    <v-row v-if="value.length > 0 && headerTitle === true" class="font-weight-black" no-gutters>
      <v-col v-for="(info, field) in fields" :key="field" class="mr-3 text-subtitle-1 mb-n2" v-bind="info.colAttrs">
        <v-row v-if="info.type === 'radio'" no-gutters>
          <v-col v-for="name in info.option" :key="name" v-bind="info.fieldAttrInput">{{ name }}
          </v-col>
        </v-row>
        <div v-else-if="info.type === 'checkbox'" class="mt-n3">{{ info.name }}</div>
        <span v-else>{{ info.name }}</span>
      </v-col>
    </v-row>
    <v-divider v-if="headerTitle && value.length > 0" class="mt-2 mb-2"/>
    <div class=" " v-for="(valueLine, index) in value" :key="index">
      <lineFieldsContainer
          :fieldAttrRow="fieldAttrRow"
          :fields="fields"
          :headerTitle="headerTitle"
          :hideDetails="hideDetails"
          :linePrefix="linePrefix"
          :order="index"
          :rerunsArray="getRerunsArray(index)"
          :templateContent="templateContent"
          :updateOnChange="updateOnChange"
          :valueArray="value"
          :valueLine="valueLine"
          @click="checkClicked(index, ...arguments)"
          v-on="$listeners"
          @field-value-changed="checkInput(index, ...arguments)"
          @field-line-changed="checkLineChanged(index, ...arguments)"
          @add-clicked="addLine()">
      </lineFieldsContainer>
    </div>
    <v-divider v-if="headerTitle && value.length > 0" class="mb-4"></v-divider>
    <div v-if="addable === true">
      <v-dialog v-model="adderDialog">
        <v-card>
          <dialogTitle :title="addButtTitle"></dialogTitle>
          <v-card-text class="mt-3">
            <v-form ref="addingLineForm" v-model="addingFormValid" :lazy-validation="true">
              <lineFieldsContainer :fieldAttrRow="fieldAttrRow" :fields="addingLineFields" :headerTitle="false"
                                   :hideDetails="hideDetails" :linePrefix="linePrefix" :rerunsArray="getRerunsArray()"
                                   :templateContent="templateContent" :valueLine="addingLine" v-on="$listeners"
                                   @field-line-changed="checkAddingRowInput(...arguments)"/>
            </v-form>
          </v-card-text>
          <v-divider/>
          <v-card-actions>
            <primaryButton v-if="addable === true" :id="linePrefix + '_pop_ok'" class="mr-2" color="primary"
                           icon="mdi-plus" label="OK" type="button" v-bind="buttonConf" @click="submitAddedRow()"/>
            <primaryButton v-if="addable === true" class="mr-2" color="primary" icon="mdi-plus" label="Add Another"
                           type="button" v-bind="buttonConf" @click="submitAndAdd()"/>
            <cancel v-if="addable === true" @click="checkAddingClicked()"/>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <primaryButton v-if="addable === true" :attributesArray="{ id: linePrefix + '_mainAdder' }"
                     :disabled="disabled || !isValid" :label="addButtTitle" class="mr-2 mt-2" color="primary"
                     icon="mdi-plus" type="button" v-bind="buttonConf" @click="addLine()"/>
    </div>
  </div>
</template>

<script>
import dialogTitle from '@/commonComponents/dialogTitle.vue';
import cancel from '@/commonComponents/cancel.vue';
import primaryButton from '@/commonComponents/primaryButton.vue';
import lineFieldsContainer from '@/commonComponents/lineFieldsContainer.vue';

export default {
  components: {lineFieldsContainer, primaryButton, cancel, dialogTitle},
  props: {
    componentType: {type: String, default: 'steps'},
    fieldAttrRow: {},
    fields: {},
    value: {
      type: [Array, String],
      default() {
        return [];
      },
    },
    isValid: {type: Boolean, default: true},
    headerTitle: {type: Boolean, default: false},
    addable: {type: Boolean, default: true},
    addButtTitle: {type: String, default: 'Add'},
    linePrefix: {type: String, default: ''},
    hideDetails: {type: Boolean, default: false},
    buttonConf: {
      type: Object,
      default() {
        return {};
      },
    },
    rerunsObject: {
      type: Object,
      default() {
        return {};
      },
    },
    dialogAdder: {type: Boolean, default: false},
    updateOnChange: {type: Boolean, default: false},
    templateContent: {},
  },
  data() {
    return {
      addingLine: {},
      adderDialog: false,
      addingFormValid: true,
      //stencilInfo: false
    };
  },
  watch: {
   /* "templateContent.valueLine.c_type": function (value) {
      this.stencilInfo = false;
      if (typeof value != 'undefined' &&
          value === "stencil") {
        this.stencilInfo = true;
      }
    }*/
    /*  stencilInfo(new_val){
      console.log(new_val)
         if(new_val){
          setTimeout(()=>{this.stencilInfo=false},10000)
        }
      }, */
  },
  computed: {
    stencilInfo:{
      get() {
        return this.templateContent?.valueLine?.c_type  === "stencil";
      },
      set(newVal) {
        this.$emit('test-type-stencil', newVal);
      }
    },
    disabled() {
      let disabled = false;
      if (typeof this.templateContent != 'undefined' && typeof this.templateContent.disableAllInputs != 'undefined'
          && this.templateContent.disableAllInputs === true) {
        disabled = true;
      }
      return disabled;
    },
    addingLineFields() {
      let addingLineFields = {};
      for (let [key, info] of Object.entries(this.fields)) {
        if (typeof info.addingInput == 'undefined' || info.addingInput === true) {
          this.$set(addingLineFields, key, info);
        }
      }
      return addingLineFields;
    },
  },
  methods: {
    getRerunsArray(index) {
      let rerunsArray = [];
      if (typeof this.rerunsObject[index] != 'undefined') {
        rerunsArray = this.rerunsObject[index];
      }
      return rerunsArray;
    },

    checkClicked(index, name) {
      if (name === 'trash') {
        let localArray = [...this.value];
        localArray.splice(index, 1);
        //console.log(JSON.stringify(localArray));
        this.$emit('line-field-input', localArray);
      } else {
        this.$emit('line-clicked', index, name);
      }
    },
    checkAddingClicked() {
      this.addingLine = {};
      this.adderDialog = false;
    },
    checkAddingRowInput(value) {
      this.addingLine = value;
    },
    checkInput(index, field, value, text) {
      this.$emit('indexed-field-value-changed', index, field, value, text);
    },
    checkLineChanged(index, line) {
      let localArray = [...this.value];
      this.$set(localArray, index, line);
      this.$emit('line-field-input', localArray);
    },
    addLine() {
      //console.log(this.templateContent);
      if (typeof this.templateContent != 'undefined' &&
          typeof this.templateContent.valueLine != 'undefined' &&
          this.templateContent.valueLine.c_type != 'undefined' &&
          this.templateContent.valueLine.c_type == "stencil") {
        //console.log('trigger the message');
        this.stencilInfo = true;
      }
      let newLine = this.getNewLine();
      if (this.dialogAdder === true) {
        this.addingLine = newLine;
        this.adderDialog = true;
      } else {
        let localArray = [...this.value];
        localArray.push(newLine);
        this.$emit('line-field-input', localArray);
      }
    },
    getNewLine() {
      let newLine = {};
      for (const field of Object.keys(this.fields)) {
        if ((this.fields[field].input === undefined || this.fields[field].input === true)
            && typeof this.fields[field] != 'function') {
          let fieldValue = '';
          if (this.fields[field].default !== undefined) {
            fieldValue = this.fields[field].default;
          }
          newLine[field] = fieldValue;
        }
      }
      return newLine;
    },
    submitAddedRow() {
      if (this.$refs.addingLineForm.validate()) {
        let localArray = [...this.value];
        this.adderDialog = false;
        localArray.push(this.addingLine);
        this.$emit('line-field-input', localArray);
        this.addingLine = {};
      }
    },
    submitAndAdd() {
      if (this.$refs.addingLineForm.validate()) {
        let localArray = [...this.value];
        // this.adderDialog = false;
        localArray.push(this.addingLine);
        this.$emit('line-field-input', localArray);
        this.addingLine = this.getNewLine();
        this.$refs.addingLineForm.resetValidation();
        //this.addingFormValid = true;
      }
    },
  },
};
</script>
